.pac-container {
    background-color: white;
    border-radius: 0.375rem;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }
  
  .pac-item {
    padding: 0.5rem 1rem;
  }
  
  .pac-item-query {
    font-size: 1rem; 
  }

  .map-container {
    width: 100%;
    height: 55vh; 
  }
  
  
  @media (max-width: 768px) { 
    .map-container {
      height: 60vh;
    }
  }
  
  