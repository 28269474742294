.custom-marker {
    background-color: rgb(138, 139, 239);
    border: 1px solid #888;
    border-radius: 20px;
    padding: 8px 12px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
    font-size: 14px;
    font-weight: bold;
    color: #333;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s ease-in-out;
    width: 50px;
  }
  
  .custom-marker:hover {
    transform: scale(1.1);
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.4);
  }
  .map-container {


    overflow: hidden;
    width: 100%;
    height: 80vh;
  }
  
  input:focus {
    outline: none;
    border-color: #3182ce;
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
  }

  